//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange, getEfficiencyColor } from "@/commons";
export default {
  components: {
    XModal: () => import("@/components/XModal"),
    CContent: () => import("@/components/CContent"),
  },
  props: {
    teamId: {
      type: Number,
      default: null,
    },
    uids: {
      type: Array,
      default: () => [],
    },
    datestart: {
      type: String,
      default: null,
    },
    dateend: {
      type: String,
      default: null,
    },
    dateType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      typeVal: 1,
      speedVal: 1,
      tableData: [],
    };
  },
  methods: {
    imgChange,
    getEfficiencyColor,
    opened() {
      this.$nextTick(() => {
        this.typeVal = 1;
        this.handleRank();
      });
    },
    handleRank() {
      this.loading = true;
      let wk = null;
      //如果是按周查询，则wk获取组件传回的天数的前一天,否则wk为组件传回的日期
      if (this.dateType == 1) {
        const seldate = new Date(this.dateend);
        wk = new Date(seldate.setDate(seldate.getDate() - 1)).timeFormat(
          "yyyy-MM-dd 23:59:59"
        );
      } else if (this.dateType == 2) {
        wk = this.dateend.timeFormat("yyyy-MM-dd 23:59:59");
      }
      const data = {
        uids: this.uids.map((m) => m.UsId),
        datestart: this.datestart.timeFormat("yyyy-MM-dd 00:00:01"),
        dateend: wk,
        teamId: this.teamId,
        rankType: this.typeVal,
      };
      this.$http
        .post("/User/Work/GetEfficiencyRanking.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.tableData = resp.data;
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
